import React from "react";
import Phone from "../../assets/phone.svg";
import Mail from "../../assets/mail.svg";

function termsofuse() {
  return (
    <div className="container mx-auto p-5 md:px-8 lg:px-20">
      <div className=" flex justify-start items-center gap-10 my-5 hidden lg:flex">
        <a href="/privacypolicy" className="hover">
          {" "}
          <p className="font-newblack text-xl hover font-semibold inter">
            Privacy Policy
          </p>
        </a>
        <a href="/terms&condition" className="hover">
          <p className="font-newblack text-xl hover font-semibold inter">
            Terms & Condition
          </p>
        </a>
        <a href="/termsofuse" className="hover">
          <p className="font-newblack hover text-xl underline underline-offset-8 font-semibold inter">
            Terms of Use
          </p>
        </a>
      </div>
      <div className="my-5 md:my-20">
        <h1 className="font-semibold text-center font-newblack text-[40px] leading-[48px] md:text-[69px] md:leading-[82px] mb-3">
          Terms of Use
        </h1>
        <p className="font-newblack text-xl text-center font-bold inter">
          Last Updated: 25 July 2024
        </p>
      </div>
      <div className="grid grid-cols-1 gap-5 my-10 lg:grid-cols-3 md:gap-10 md:my-24">
        <div className="col-span-2 order-last lg:order-none">
          <p className="text-2xl font-bold poppins font-newblack pt-2 md:pt-6 md:text-3xl">
            Terms of Use
          </p>
          <p className="pt-2 inter text-slate-500 mb-5 font-medium text-md leading-[30px]">
            Welcome to JourneyX, the mobile application operated by Advance
            Adventures Gear Technologies Pvt Ltd ("TechAAG", "we", "us", or
            "our"). By accessing or using our JourneyX mobile application (the
            "App"), you agree to comply with and be bound by the following terms
            and conditions ("Terms of Use"). If you do not agree to these terms,
            please do not use our App.
          </p>
          <p className="text-2xl font-bold poppins font-newblack pt-2 md:pt-6 md:text-3xl">
            Terms of Use Summary
          </p>
          <div id="acceptanceofterms">
            <p className="text-xl font-bold poppins font-newblack pt-2 md:pt-6 ">
              1. Acceptance of Terms
            </p>
            <p className="pt-2 inter text-slate-500 font-medium text-md leading-[30px]">
              By accessing or using our App, you agree to be bound by these
              Terms of Use and our Privacy Policy. If you do not agree to these
              terms, you must not use our App.
            </p>
          </div>
          <div id="legalcontract">
            <p className="text-xl font-bold poppins font-newblack pt-2 md:pt-6 ">
              2. Legal Contract
            </p>
            <p className="pt-2 inter text-slate-500 font-medium text-md leading-[30px]">
              When you access or use our App, you enter into a legal contract
              with TechAAG. By using our App, you agree to these Terms of Use,
              our Privacy Policy, and any additional terms applicable to
              specific services you utilize.
            </p>
          </div>
          <div id="servicesprovided">
            <p className="text-xl font-bold poppins font-newblack pt-2 md:pt-6 ">
              3. Services Provided
            </p>
            <p className="pt-2 inter text-slate-500 font-medium text-md leading-[30px]">
              JourneyX provides travel technology solutions designed to enhance
              the travel experience, including but not limited to itinerary
              management, booking services, and travel assistance.
            </p>
          </div>
          <div id="intellectualproperty">
            <p className="text-xl font-bold poppins font-newblack pt-2 md:pt-6 ">
              4. Intellectual Property
            </p>
            <p className="pt-2 inter text-slate-500 font-medium text-md leading-[30px]">
              All content, software, and technology on our App are owned by or
              licensed to TechAAG and are protected by intellectual property
              laws. You may not copy, distribute, transmit, display, perform,
              reproduce, publish, license, create derivative works from,
              transfer, or sell any information, software, products, or services
              obtained from our App without our prior written consent.
            </p>
          </div>
          <div id="userconduct">
            <p className="text-xl font-bold poppins font-newblack pt-2 md:pt-6 ">
              5. User Conduct
            </p>
            <p className="pt-2 inter text-slate-500 font-medium text-md leading-[30px]">
              You agree to use our App only for lawful purposes and in a way
              that does not infringe the rights of, restrict, or inhibit anyone
              else's use and enjoyment of the App. Prohibited behavior includes
              harassing or causing distress or inconvenience to any other user,
              transmitting obscene or offensive content, or disrupting the
              normal flow of dialogue within our App.
            </p>
          </div>
          <div id="accountsecurity">
            <p className="text-xl font-bold poppins font-newblack pt-2 md:pt-6 ">
              6. Account Security
            </p>
            <p className="pt-2 inter text-slate-500 font-medium text-md leading-[30px]">
              You are responsible for maintaining the confidentiality of your
              account information, including your password, and for all
              activities that occur under your account. You agree to notify
              TechAAG immediately of any unauthorized use of your account or any
              other breach of security. TechAAG will not be liable for any loss
              that you may incur as a result of someone else using your password
              or account, either with or without your knowledge.
            </p>
          </div>
          <div id="privacy">
            <p className="text-xl font-bold poppins font-newblack pt-2 md:pt-6 ">
              7. privacy
            </p>
            <p className="pt-2 inter text-slate-500 font-medium text-md leading-[30px]">
              Your use of our App is also governed by our Privacy Policy, which
              addresses our collection, use, and storage of your personal
              information. By using our App, you consent to the collection and
              use of information as described in our Privacy Policy.
            </p>
          </div>
          <div id="modificationtoservicesandterms">
            <p className="text-xl font-bold poppins font-newblack pt-2 md:pt-6 ">
              8. Modifications to Services and Terms
            </p>
            <p className="pt-2 inter text-slate-500 font-medium text-md leading-[30px]">
              TechAAG reserves the right to modify or discontinue, temporarily
              or permanently, the App (or any part thereof) with or without
              notice. We also reserve the right to change these Terms of Use at
              any time. Your continued use of the App after such changes have
              been made will constitute your acceptance of the new terms.
            </p>
          </div>
          <div id="termination">
            <p className="text-xl font-bold poppins font-newblack pt-2 md:pt-6 ">
              9. Termination
            </p>
            <p className="pt-2 inter text-slate-500 font-medium text-md leading-[30px]">
              We may terminate or suspend your access to the App at any time,
              without prior notice or liability, for any reason, including if
              you breach these Terms of Use. Upon termination, your right to use
              the App will immediately cease.
            </p>
          </div>
          <div id="limitationofliability">
            <p className="text-xl font-bold poppins font-newblack pt-2 md:pt-6 ">
              10. Limitation of Liability
            </p>
            <p className="pt-2 inter text-slate-500 font-medium text-md leading-[30px]">
              To the fullest extent permitted by law, TechAAG, its affiliates,
              officers, directors, employees, and agents shall not be liable for
              any indirect, incidental, special, consequential, or punitive
              damages, or any loss of profits or revenues, whether incurred
              directly or indirectly, or any loss of data, use, goodwill, or
              other intangible losses, resulting from (a) your use or inability
              to use the App; (b) any unauthorized access to or use of our
              servers and/or any personal information stored therein; (c) any
              interruption or cessation of transmission to or from the App; (d)
              any bugs, viruses, trojan horses, or the like that may be
              transmitted to or through our App by any third party; (e) any
              errors or omissions in any content or for any loss or damage
              incurred as a result of the use of any content posted, emailed,
              transmitted, or otherwise made available through the App; and/or
              (f) the defamatory, offensive, or illegal conduct of any third
              party.
            </p>
          </div>
          <div id="governinglaw">
            <p className="text-xl font-bold poppins font-newblack pt-2 md:pt-6 ">
              11. Governing Law
            </p>
            <p className="pt-2 inter text-slate-500 font-medium text-md leading-[30px]">
              These Terms of Use shall be governed by and construed in
              accordance with the laws of the jurisdiction in which TechAAG is
              established, without regard to its conflict of law principles. You
              agree to submit to the personal and exclusive jurisdiction of the
              courts located within that jurisdiction.
            </p>
          </div>
          <div id="contactus">
            <p className="text-xl font-bold poppins font-newblack pt-2 md:pt-6 ">
              12. Contact us
            </p>
            <p className="pt-2 inter text-slate-500 font-medium text-md leading-[30px]">
              If you have any questions or concerns about this Privacy Policy or
              our privacy practices, please contact us:
              <br />
              <span className="text-black">
                Advance Adventures Gear Technologies Pvt Ltd
              </span>
              <span className="flex gap-2 my-2 text-black">
                <img src={Mail} alt="journey-x" />
                <a href="mailto:dev@techaag.com" className="hover">
                  dev@techaag.com
                </a>
              </span>
              <span className="flex gap-2 mb-2 text-black">
                <img src={Phone} alt="journey-x" />
                <a href="tel:+91-2265168147" className="hover">
                  +91-2265168147
                </a>
              </span>
            </p>
          </div>
        </div>
        <div>
          <p className="text-2xl font-bold poppins font-newblack py-2 md:pt-6 ">
            Table of Content:
          </p>
          <ul className="list-disc text-black ml-5">
            <a href="#acceptanceofterms">
              <li className="underline mb-2 hover">1. Acceptance of Terms</li>
            </a>
            <a href="#legalcontract">
              <li className="underline mb-2 hover">2. Legal Contract</li>
            </a>
            <a href="#servicesprovided">
              <li className="underline mb-2 hover">3. Services Provided</li>
            </a>
            <a href="#intellectualproperty">
              <li className="underline mb-2 hover">4. Intellectual Property</li>
            </a>
            <a href="#userconduct">
              <li className="underline mb-2 hover">5. User Conduct</li>
            </a>
            <a href="#accountsecurity">
              <li className="underline mb-2 hover">6. Account Security</li>
            </a>
            <a href="#privacy">
              <li className="underline mb-2 hover">7. Privacy</li>
            </a>
            <a href="#modificationtoservicesandterms">
              <li className="underline mb-2 hover">
                8. Modifications to Services and Terms
              </li>
            </a>
            <a href="#termination">
              <li className="underline mb-2 hover">9. Termination</li>
            </a>
            <a href="#limitationofliability">
              <li className="underline mb-2 hover">
                10. Limitation of Liability
              </li>
            </a>
            <a href="#governinglaw">
              <li className="underline mb-2 hover">11. Governing Law</li>
            </a>
            <a href="#contactus">
              <li className="underline mb-2 hover">12. Contact Us</li>
            </a>
          </ul>
          <hr className="mt-10 h-0.5" />
        </div>
      </div>
    </div>
  );
}

export default termsofuse;
