import React from "react";
import Image1 from "../../assets/aboutus/image1.svg";
import Image2 from "../../assets/aboutus/image2.svg";
import Image3 from "../../assets/aboutus/image3.svg";
import BackgroundImages from "../../assets/home/section4/background.svg";

function aboutus() {
  return (
    <div
      className="relative"
      style={{
        backgroundImage: `url(${BackgroundImages})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPositionY: "top 450px",
      }}
    >
      <div className="container mx-auto p-5 md:px-8 lg:px-20">
        <div className="my-5 md:my-10">
          <h1 className="font-semibold text-center font-newblack text-[40px] leading-[48px] md:text-[69px] md:leading-[82px]">
            About Us
          </h1>
          <p className="font-blue text-xl text-center font-bold inter">
            The Best Trip Planner App
          </p>
        </div>
        <div className="grid grid-cols-1 gap-5 my-10 items-center lg:grid-cols-2 md:gap-10 md:my-24">
          <div>
            <p className="text-3xl font-bold poppins font-newblack pt-2 md:pt-6 md:text-5xl">
              Who We Are
            </p>
            <p className="pt-6 inter text-slate-500 font-medium text-md leading-[34px]">
              At JourneyX, we are passionate about transforming travel
              experiences for our users. Our team comprises dedicated
              professionals with extensive expertise in travel and technology,
              committed to delivering seamless and personalized travel
              solutions. We believe that every journey should be memorable,
              hassle-free, and tailored to your unique preferences.
            </p>
          </div>
          <div className="flex justify-center items-center">
            <img src={Image1} alt="journey-x" />
          </div>
        </div>
        <div className="grid grid-cols-1 gap-5 my-10 items-center lg:grid-cols-2 md:gap-10 md:my-24">
          <div className="order-last flex justify-center items-center lg:order-none">
            <img src={Image2} alt="journey-x" />
          </div>
          <div>
            <p className="text-3xl font-bold poppins font-newblack pt-2 md:pt-6 md:text-5xl">
              What We Do
            </p>
            <p className="pt-6 inter text-slate-500 font-medium text-md leading-[34px]">
              JourneyX offers a state-of-the-art travel itinerary management
              system designed to enhance your travel experience. Our app
              provides personalized travel suggestions and real-time tracking,
              making dynamic adjustments to your itinerary based on your
              preferences, even offline. Whether it's planning your day-to-day
              activities, receiving timely updates, or exploring new
              destinations, JourneyX is your reliable travel companion.
            </p>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-5 my-10 items-center lg:grid-cols-2 md:gap-10 md:my-24">
          <div>
            <p className="text-3xl font-bold poppins font-newblack pt-2 md:pt-6 md:text-5xl">
              Our Strength
            </p>
            <p className="pt-6 inter text-slate-500 font-medium text-md leading-[34px]">
              Our strength lies in our innovative technology and user-centric
              approach. JourneyX ensures uninterrupted service by offering
              offline functionality, allowing you to access your itineraries and
              receive updates without constant internet connectivity. We provide
              real-time notifications, interactive maps, and curated insights to
              guide you to hidden gems and must-see landmarks. With robust
              communication tools and a focus on privacy and security, JourneyX
              ensures your travel data is protected and your experience is
              seamless and enjoyable.
            </p>
          </div>
          <div className="flex justify-center items-center">
            <img src={Image3} alt="journey-x" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default aboutus;
