import React from "react";
import work from "../../assets/home/section1/work.svg";
import QrCode from "../../assets/home/section1/qrcode.svg";
import Mobile from "../../assets/home/section1/mobile.png";
import Group from "../../assets/home/section1/Group.svg";
import Ticket from "../../assets/home/section1/ticket-star 1.svg";
import Send from "../../assets/home/section1/send.svg";
import Maps from "../../assets/home/section1/Maps.svg";
import Arrow from "../../assets/home/section1/arrow.svg";
import Plane from "../../assets/home/section1/plane.svg";

const section1 = () => {
  return (
    <div className="container mx-auto p-5 md:px-8 lg:px-20">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:mt-10">
        <div>
          <div className="md:pt-20">
            <div className="shadow flex items-center w-fit gap-3 rounded-full bg-white px-4 py-2">
              <p className="font-bold font-blue text-sm poppins">
                Explore the world!
              </p>
              <img src={work} className="w-6" alt="journey-x" />
            </div>

            <div className="pt-2 md:pt-7">
              <p className="font-semibold font-newblack text-[40px] leading-[48px] md:text-[69px] md:leading-[82px]">
                View Your <span className="font-blue">Itinerary</span> Just in
                <span className="font-red"> 1 Click</span>
              </p>
              <p className="text-slate-500 inter text-sm pt-2 md:w-10/12 md:pt-7 md:text-lg">
                We always make our customer happy by providing as many choices
                as possible
              </p>
            </div>

            <div className="gap-1 pt-7 hidden md:flex">
              <img src={QrCode} className="max-w-24" alt="journey-x" />
              <div className="flex flex-col flex-start justify-center">
                <p className="font-bold text-lg font-newblack poppins">
                  Download The <span className="font-blue">Journey</span>
                  <span className="font-red">X</span> App Now!
                </p>
                <p className="text-slate-500 text-lg inter">
                  Click on QR code to install JourneyX Mobile App
                </p>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div>
            <div className="flex justify-center relative">
              <img src={Mobile} className="mt-20" alt="journey-x" loading="lazy"/>
              <img src={Group} className="absolute z-[-1]" alt="journey-x" />
              <img
                src={Arrow}
                alt="journey-x"
                className="absolute z-[-1] bottom-[25%] right-[-10px] hidden lg:block"
              />
              <img
                src={Plane}
                alt="journey-x"
                className="absolute top-[40%] right-[25px] hidden lg:block"
              />
              <img
                src={Ticket}
                className="absolute bottom-0 right-[-15%] hidden lg:block"
                alt="journey-x"
              />
              <img
                src={Send}
                className="absolute top-[50%] left-[-10%] hidden lg:block"
                alt="journey-x"
              />
              <img
                src={Maps}
                className="absolute bottom-[20%]  left-0 hidden lg:block"
                alt="journey-x"
              />
            </div>
            <div className="flex flex-col items-center gap-1 pt-7 md:hidden">
              <img src={QrCode} className="max-w-24" alt="journey-x" />
              <div className="flex flex-col flex-start text-center justify-center">
                <p className="font-bold poppins text-sm md:text-lg">
                  Download The <span className="font-blue">Journey</span>
                  <span className="font-red">X</span> App Now!
                </p>

                <p className="text-slate-500 inter text-sm md:text-lg">
                  Click on QR code to install JourneyX Mobile App
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default section1;
