import Home from "./pages/home/index";
import { BrowserRouter, Routes, Route } from "react-router-dom";
// import { HashRouter, Route, Routes } from "react-router-dom";
import Header from "./components/header/index";
import Footer from "./components/footer/index";
import Aboutus from "./pages/aboutus/index";
import Privacy from "./pages/privacypolicy/index"
import Termsofuse from "./pages/termsofuse/index";
import Termsandcondition from "./pages/terms&condition";

function App() {
  return (
    // <HashRouter>
    //   <Header />
    //   <Routes>
    //     <Route path="/" component={<Home />} />
    //     <Route path="/aboutus" element={<Aboutus />} />
    //     {/* <Route path="/privacypolicy" element={<Privacy />} />
    //     <Route path="/termsofuse" element={<Termsofuse />} />
    //     <Route path="/terms&condition" element={<Termsandcondition />} /> */}
    //   </Routes>
    //   <Footer />
    // </HashRouter>
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/aboutus" element={<Aboutus />} />
        <Route path="/privacypolicy" element={<Privacy />} />
        <Route path="/termsofuse" element={<Termsofuse />} />
        <Route path="/terms&condition" element={<Termsandcondition />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
