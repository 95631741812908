import React from "react";
import Phone from "../../assets/phone.svg";
import Mail from "../../assets/mail.svg";

function privacypolicy() {
  return (
    <div className="container mx-auto p-5 md:px-8 lg:px-20">
      <div className=" flex justify-start items-center gap-10 my-5 hidden lg:flex">
        <a href="/privacypolicy" className="hover">
          {" "}
          <p className="font-newblack text-xl hover underline underline-offset-8 font-semibold inter">
            Privacy Policy
          </p>
        </a>
        <a href="/terms&condition" className="hover">
          <p className="font-newblack text-xl hover font-semibold inter">
            Terms & Condition
          </p>
        </a>
        <a href="/termsofuse" className="hover">
          <p className="font-newblack hover text-xl font-semibold inter">
            Terms of Use
          </p>
        </a>
      </div>
      <div className="my-5 md:my-20">
        <h1 className="font-semibold text-center font-newblack text-[40px] leading-[48px] md:text-[69px] md:leading-[82px] mb-3">
          Privacy Policy
        </h1>
        <p className="font-newblack text-xl text-center font-bold inter">
          Last Updated: 25 July 2024
        </p>
      </div>
      <div className="grid grid-cols-1 gap-5 my-10 lg:grid-cols-3 md:gap-10 md:my-24">
        <div className="col-span-2 order-last lg:order-none">
          <p className="text-2xl font-bold poppins font-newblack pt-2 md:pt-6 md:text-3xl">
            Privacy Summary
          </p>
          <div id="introduction">
            <p className="text-xl font-bold poppins font-newblack pt-2 md:pt-6 ">
              1. Introduction
            </p>
            <p className="pt-2 inter text-slate-500 font-medium text-md leading-[30px]">
              Thank you for using the JourneyX mobile app (the "App") and
              visiting our website www.techaag.com (the "Website"), operated by
              Tech AAG (Advance Adventures Gear Technologies Pvt Ltd). Your
              privacy is important to us. This Privacy Policy explains how we
              collect, use, disclose, and safeguard your information when you
              use our App and Website. Please read this policy carefully. If you
              do not agree with the terms of this Privacy Policy, please do not
              access the App or Website.
            </p>
          </div>
          <div id="informationwecollect">
            <p className="text-xl font-bold poppins font-newblack pt-2 md:pt-6 ">
              2. Information We Collect
            </p>
            <p className="pt-2 inter text-slate-500 font-medium text-md leading-[30px]">
              We may collect and process the following information about you:
              <br />
              <span className="text-black font-semibold">
                ·Personal Information:
              </span>{" "}
              We may collect personal information that you provide to us, such
              as your name, email address, phone number, and payment information
              when you register an account, make a booking, or communicate with
              us. <br />
              <span className="text-black font-semibold">
                ·Device Information:
              </span>{" "}
              We may collect information about your mobile device, including the
              device model, operating system version, unique device identifiers,
              and mobile network information. <br />
              <span className="text-black font-semibold">
                ·Location Information:
              </span>{" "}
              With your consent, we may collect precise location data from your
              mobile device when the App is running in the foreground or
              background. You can opt-out of sharing location data through your
              device settings.
              <br />{" "}
              <span className="text-black font-semibold"> ·Usage Data:</span> We
              may collect information about how you interact with the App, such
              as the features you use, pages visited, and the time and date of
              your interactions.
            </p>
          </div>
          <div id="yourinformation">
            <p className="text-xl font-bold poppins font-newblack pt-2 md:pt-6 ">
              3. How We Use Your Information
            </p>
            <p className="pt-2 inter text-slate-500 font-medium text-md leading-[30px]">
              We may use the information we collect for various purposes,
              including: <br />
              <ul className="list-disc text-black ml-5">
                <li>To provide and maintain our App and Website.</li>
                <li>
                  {" "}
                  To personalize your experience and deliver targeted content
                  and advertisements.
                </li>
                <li>
                  To communicate with you, including sending promotional
                  materials and updates.
                </li>
                <li> To process transactions and bookings.</li>
                <li>
                  {" "}
                  To improve our products and services and conduct research and
                  analysis.
                </li>
              </ul>
            </p>
          </div>
          <div id="disclosure">
            <p className="text-xl font-bold poppins font-newblack pt-2 md:pt-6 ">
              4. Disclosure of Your Information
            </p>
            <p className="pt-2 inter text-slate-500 font-medium text-md leading-[30px]">
              We may disclose your information to third parties under certain
              circumstances, such as: <br />
              <ul className="list-disc text-black ml-5">
                <li>
                  {" "}
                  With service providers and business partners who assist us in
                  operating our App and Website.
                </li>
                <li>
                  With third parties as required by law or to protect our
                  rights.
                </li>
              </ul>
            </p>
          </div>
          <div id="security">
            <p className="text-xl font-bold poppins font-newblack pt-2 md:pt-6 ">
              5. Security of Your Information
            </p>
            <p className="pt-2 inter text-slate-500 font-medium text-md leading-[30px]">
              We use reasonable measures to protect the confidentiality and
              security of your information. However, no method of transmission
              over the Internet or method of electronic storage is completely
              secure.
            </p>
          </div>
          <div id="choices">
            <p className="text-xl font-bold poppins font-newblack pt-2 md:pt-6 ">
              6. Your Choices
            </p>
            <p className="pt-2 inter text-slate-500 font-medium text-md leading-[30px]">
              You may choose not to provide certain information, but this may
              limit your ability to use certain features of our App and Website.
              You can also opt-out of receiving promotional emails by following
              the instructions in those emails.
            </p>
          </div>
          <div id="changestoprivacypolicy">
            <p className="text-xl font-bold poppins font-newblack pt-2 md:pt-6 ">
              7. Changes to This Privacy Policy
            </p>
            <p className="pt-2 inter text-slate-500 font-medium text-md leading-[30px]">
              We may update this Privacy Policy from time to time to reflect
              changes in our practices or legal requirements. We will notify you
              of any material changes by posting the updated policy on our
              Website.
            </p>
          </div>
          <div id="contactus">
            <p className="text-xl font-bold poppins font-newblack pt-2 md:pt-6 ">
              8. Contact us
            </p>
            <p className="pt-2 inter text-slate-500 font-medium text-md leading-[30px]">
              If you have any questions or concerns about this Privacy Policy or
              our privacy practices, please contact us:
              <br />
              <span className="text-black">
                Advance Adventures Gear Technologies Pvt Ltd
              </span>
              <span className="flex gap-2 my-2 text-black">
                <img src={Mail} alt="journey-x" />
                <a href="mailto:dev@techaag.com" className="hover">
                  dev@techaag.com
                </a>
              </span>
              <span className="flex gap-2 mb-2 text-black">
                <img src={Phone} alt="journey-x" />
                <a href="tel:+91-2265168147" className="hover">
                  +91-2265168147
                </a>
              </span>
            </p>
          </div>
        </div>
        <div>
          <p className="text-2xl font-bold poppins font-newblack py-2 md:pt-6 ">
            Table of Content:
          </p>
          <ul className="list-disc text-black ml-5">
            <a href="#introduction">
              <li className="underline mb-2 hover">1. Introduction</li>
            </a>
            <a href="#informationwecollect">
              <li className="underline mb-2 hover">
                2. Information We Collect
              </li>
            </a>
            <a href="#yourinformation">
              <li className="underline mb-2 hover">
                3. How We Use Your Information
              </li>
            </a>
            <a href="#disclosure">
              <li className="underline mb-2 hover">
                4. Disclosure of Your Information
              </li>
            </a>
            <a href="#security">
              <li className="underline mb-2 hover">
                5. Security of Your Information
              </li>
            </a>
            <a href="#yourchoices">
              <li className="underline mb-2 hover">6. Your Choices</li>
            </a>
            <a href="#changestoprivacypolicy">
              <li className="underline mb-2 hover">
                7. Changes to This Privacy Policy
              </li>
            </a>
            <a href="#contactus">
              <li className="underline mb-2 hover">8. Contact Us</li>
            </a>
          </ul>
          <hr className="mt-10 h-0.5" />
        </div>
      </div>
    </div>
  );
}

export default privacypolicy;
