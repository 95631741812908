import React from "react";
import Mobile1 from "../../assets/home/section4/Mobile1.png";
import Mobile2 from "../../assets/home/section4/Mobile2.png";
import BackgroundImages from "../../assets/home/section4/background.svg";
import Objects from "../../assets/home/section4/OBJECTS.svg";
function section4() {
  return (
    <div>
      {" "}
      <div
        className="relative"
        style={{
          backgroundImage: `url(${BackgroundImages})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <img
          src={Objects}
          className="absolute top-0 right-0 hidden z-[-1] md:block"
          alt="journey-x"
        />
        <div className="container mx-auto p-5 md:px-8 md:pb-20 lg:px-20">
          <div className="grid grid-cols-1 items-start md:grid-cols-2 gap-4">
            <div className="relative  flex justify-center">
              <img src={Mobile1} alt="journey-x" className="w-[250px]" />
              <img
                src={Mobile2}
                alt="journey-x"
                className="absolute bottom-[-200px] left-[22%] w-[300px] hidden md:flex"
              />
            </div>
            <div>
              <div>
                <p className="font-blue uppercase text-base font-bold poppins">
                  FAQ
                </p>
                <p className="text-3xl font-bold poppins font-newblack pt-2 md:pt-6 md:text-5xl">
                  Frequently Asked Questions
                </p>
                <ul className="max-w-2xl mx-auto mt-10">
                  <li className="bg-white mt-3 rounded-lg">
                    <details className="group">
                      <summary className="flex justify-between items-center gap-3 px-4 py-3 font-medium hover:cursor-pointer">
                        <span className="poppins">1. What is JourneyX?</span>
                        <svg
                          className="w-5 h-5 text-gray-500 transition group-open:rotate-90"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                          ></path>
                        </svg>
                      </summary>

                      <article className="px-4 pb-4">
                        <p className="inter font-normal text-sm text-slate-500">
                          JourneyX is a travel mobile application designed to
                          help you plan, manage, and enhance your travel
                          experiences. It offers features like itinerary
                          management, booking services, local recommendations,
                          and more.
                        </p>
                      </article>
                    </details>
                  </li>
                  <li className="bg-white mt-5 rounded-lg">
                    <details className="group">
                      <summary className="flex justify-between items-center gap-3 px-4 py-3 font-medium hover:cursor-pointer">
                        <span className="poppins">
                          2. How do I download JourneyX?
                        </span>
                        <svg
                          className="w-5 h-5 text-gray-500 transition group-open:rotate-90"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                          ></path>
                        </svg>
                      </summary>

                      <article className="px-4 pb-4">
                        <p className="inter font-normal text-sm text-slate-500">
                          You can download JourneyX for free from the App Store
                          (iOS) or Google Play Store (Android) by searching for
                          "JourneyX" and following the installation
                          instructions.
                        </p>
                      </article>
                    </details>
                  </li>
                  <li className="bg-white mt-3 rounded-lg">
                    <details className="group">
                      <summary className="flex justify-between items-center gap-3 px-4 py-3 font-medium hover:cursor-pointer">
                        <span className="poppins">
                          3. Is JourneyX available worldwide?
                        </span>
                        <svg
                          className="w-5 h-5 text-gray-500 transition group-open:rotate-90"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                          ></path>
                        </svg>
                      </summary>

                      <article className="px-4 pb-4">
                        <p className="inter font-normal text-sm text-slate-500">
                          Yes, JourneyX is available globally. You can use it to
                          plan your trips anywhere around the world.
                        </p>
                      </article>
                    </details>
                  </li>
                  <li className="bg-white mt-3 rounded-lg">
                    <details className="group">
                      <summary className="flex justify-between items-center gap-3 px-4 py-3 font-medium hover:cursor-pointer">
                        <span className="poppins">
                          4. What can I do with JourneyX?
                        </span>
                        <svg
                          className="w-5 h-5 text-gray-500 transition group-open:rotate-90"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                          ></path>
                        </svg>
                      </summary>

                      <article className="px-4 pb-4">
                        <p className="inter font-normal text-sm text-slate-500">
                          With JourneyX, you can create and manage travel
                          itineraries, book accommodations and activities,
                          discover local attractions and dining options, and
                          share your travel plans with others.
                        </p>
                      </article>
                    </details>
                  </li>
                  <li className="bg-white mt-3 rounded-lg">
                    <details className="group">
                      <summary className="flex justify-between items-center gap-3 px-4 py-3 font-medium hover:cursor-pointer">
                        <span className="poppins">
                          5. Is my information secure with JourneyX?
                        </span>
                        <svg
                          className="w-5 h-5 text-gray-500 transition group-open:rotate-90"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                          ></path>
                        </svg>
                      </summary>

                      <article className="px-4 pb-4">
                        <p className="inter font-normal text-sm text-slate-500">
                          Yes, we take the security and privacy of your
                          information seriously. We use industry-standard
                          practices to safeguard your data. For more details,
                          please refer to our Privacy Policy.
                        </p>
                      </article>
                    </details>
                  </li>
                  <li className="bg-white mt-3 rounded-lg">
                    <details className="group">
                      <summary className="flex justify-between items-center gap-3 px-4 py-3 font-medium hover:cursor-pointer">
                        <span className="poppins">
                          6. How can I contact customer support?
                        </span>
                        <svg
                          className="w-5 h-5 text-gray-500 transition group-open:rotate-90"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                          ></path>
                        </svg>
                      </summary>

                      <article className="px-4 pb-4">
                        <p className="inter font-normal text-sm text-slate-500">
                          You can reach our customer support team by Advance
                          Adventures Gear Technologies Pvt Ltd, Email: 
                          <a href="mailto:dev@techaag.com" className="hover text-black"> dev@techaag.com</a>, Phone: <a href="tel:+91-2265168147" className="hover text-black">+91-2265168147</a>, or through the
                          App's support section. We're here to help you with any
                          questions or issues you may have.
                        </p>
                      </article>
                    </details>
                  </li>
                  <li className="bg-white mt-3 rounded-lg">
                    <details className="group">
                      <summary className="flex justify-between items-center gap-3 px-4 py-3 font-medium hover:cursor-pointer">
                        <span className="poppins">
                          7. Can I use JourneyX offline?
                        </span>
                        <svg
                          className="w-5 h-5 text-gray-500 transition group-open:rotate-90"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                          ></path>
                        </svg>
                      </summary>

                      <article className="px-4 pb-4">
                        <p className="inter font-normal text-sm text-slate-500">
                          Yes, JourneyX offers offline access to certain
                          features, such as accessing your saved itineraries and
                          viewing offline maps. Some functionalities, like
                          real-time updates and bookings, may require internet
                          connectivity.
                        </p>
                      </article>
                    </details>
                  </li>
                  <li className="bg-white mt-3 rounded-lg">
                    <details className="group">
                      <summary className="flex justify-between items-center gap-3 px-4 py-3 font-medium hover:cursor-pointer">
                        <span className="poppins">
                          8. How do I create an itinerary in JourneyX?
                        </span>
                        <svg
                          className="w-5 h-5 text-gray-500 transition group-open:rotate-90"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                          ></path>
                        </svg>
                      </summary>

                      <article className="px-4 pb-4">
                        <p className="inter font-normal text-sm text-slate-500">
                          To create an itinerary, simply navigate to the "Create
                          Itinerary" section in the App, enter your travel
                          details (dates, destinations, activities), and save.
                          You can edit and update your itinerary as needed.
                        </p>
                      </article>
                    </details>
                  </li>
                  <li className="bg-white mt-3 rounded-lg">
                    <details className="group">
                      <summary className="flex justify-between items-center gap-3 px-4 py-3 font-medium hover:cursor-pointer">
                        <span className="poppins">
                          9. Does JourneyX offer travel recommendations?
                        </span>
                        <svg
                          className="w-5 h-5 text-gray-500 transition group-open:rotate-90"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                          ></path>
                        </svg>
                      </summary>

                      <article className="px-4 pb-4">
                        <p className="inter font-normal text-sm text-slate-500">
                          Yes, JourneyX provides personalized travel
                          recommendations based on your preferences and
                          location. Discover local attractions, restaurants, and
                          hidden gems recommended by other travelers.
                        </p>
                      </article>
                    </details>
                  </li>
                  <li className="bg-white mt-3 rounded-lg">
                    <details className="group">
                      <summary className="flex justify-between items-center gap-3 px-4 py-3 font-medium hover:cursor-pointer">
                        <span className="poppins">
                          10. Can I share my itinerary with others?
                        </span>
                        <svg
                          className="w-5 h-5 text-gray-500 transition group-open:rotate-90"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                          ></path>
                        </svg>
                      </summary>
                      <article className="px-4 pb-4">
                        <p className="inter font-normal text-sm text-slate-500">
                          Yes, you can easily share your itinerary with friends,
                          family, or travel companions directly from the App.
                          Simply select the "Share" option within your itinerary
                          details.
                        </p>
                      </article>
                    </details>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default section4;
