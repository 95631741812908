import React from "react";
import Mobile from "../../assets/home/section2/Mobile.png";
import BackgroundImages from "../../assets/background.svg";
import Objects from "../../assets/home/section2/OBJECTS.svg";
function section2() {
  return (
    <div
      className="relative"
      style={{ backgroundImage: `url(${BackgroundImages})`,backgroundRepeat:'no-repeat',backgroundSize:'cover' }}
    >
      <img
        src={Objects}
        className="absolute top-0 left-[2%] z-[-1] hidden md:block"
        alt="journey-x"
      />
      <div className="container mx-auto p-5 md:px-8 md:py-24 lg:px-20">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <img src={Mobile} alt="journey-x" />
          </div>
          <div>
            <div>
              <p className="font-blue uppercase text-base font-bold poppins">
                ABOUT JOURNEYX
              </p>
              <p className="text-3xl font-bold poppins font-newblack pt-2 md:pt-6 md:text-5xl">
                Enjoy Your Trip, Leave the Rest to Us
              </p>
              <p className="pt-6 inter font-newblack font-medium text-justify text-sm md:text-lg">
                At JourneyX, we believe that your travel experience should be
                enjoyable and hassle-free. Our mission is to provide
                comprehensive and reliable travel solutions that allow you to
                focus on making memories, while we handle the logistics and
                details. From meticulously planned itineraries to real-time
                updates and around-the-clock support, we are dedicated to
                ensuring that every aspect of your journey is seamless and
                stress-free. Our team of experienced professionals uses
                cutting-edge technology and innovative solutions to deliver
                top-notch services tailored to your unique needs. Whether you're
                exploring new destinations, managing business travels, or
                planning the trip of a lifetime, JourneyX is here to provide
                exceptional support and services. Trust us to take care of
                everything, so you can enjoy every moment of your adventure.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default section2;
