import React from "react";
import Mobile from "../../assets/home/section3/mobile.png";
import Background from "../../assets/home/section3/BACKGROUND.svg";
import Icon1 from "../../assets/home/section3/icon1.svg";
import Icon2 from "../../assets/home/section3/icon2.svg";
import Icon3 from "../../assets/home/section3/icon3.svg";
import Icon4 from "../../assets/home/section3/qrticket.svg";
import Icon5 from "../../assets/home/section3/customer.svg";

function section3() {
  return (
    <div className="container mx-auto p-5 md:px-8 md:py-24 lg:px-20">
      <div className="grid grid-cols-1 md:grid-cols-2">
        <div>
          <div className="">
            <p className="font-blue uppercase text-base font-bold poppins">
              Key features
            </p>
            <p className="text-3xl font-newblack font-bold poppins pt-2 md:pt-6 md:text-5xl">
              We offer best services
            </p>
            <p className="pt-6 inter font-newblack font-normal">
              At JourneyX, we take pride in delivering top-tier services that
              are designed to enhance your travel experience. Our key features
              include:
            </p>
          </div>
          <div className="pt-6">
            <div className="flex gap-3 p-5 rounded-[35px] shadow-inner bg-white flex-col items-center text-center md:flex-row md:text-start md:items-start">
              <img src={Icon1} className="max-w-14" alt="journey-x" />
              <div className="flex flex-col flex-start justify-center">
                <p className="font-bold text-lg poppins font-newblack">
                  Day wise Itinerary
                </p>
                <p className="text-slate-500 text-sm inter">
                  We provide meticulously planned day-by-day itineraries to
                  ensure you make the most of your trip. Our itineraries are
                  crafted to include all must-see attractions, activities, and
                  rest periods, tailored to your preferences and needs.
                </p>
              </div>
            </div>
            <div className="flex gap-3 p-5 rounded-[35px] shadow-inner bg-white flex-col items-center text-center md:flex-row md:text-start md:items-start">
              <img src={Icon2} className="max-w-14" alt="journey-x" />
              <div className="flex flex-col flex-start justify-center">
                <p className="font-bold text-lg poppins font-newblack">
                  Real time tour update
                </p>
                <p className="text-slate-500 text-sm inter">
                  Stay informed with real-time updates throughout your journey.
                  We provide live information on changes, alerts, and essential
                  updates, ensuring you are always in the know and can adjust
                  your plans seamlessly.
                </p>
              </div>
            </div>
            <div className="flex gap-3 p-5 rounded-[35px] shadow-inner bg-white flex-col items-center text-center md:flex-row md:text-start md:items-start">
              <img src={Icon3} className="max-w-14" alt="journey-x" />
              <div className="flex flex-col flex-start justify-center">
                <p className="font-bold text-lg poppins font-newblack">
                  Driver Updates
                </p>
                <p className="text-slate-500 text-sm inter">
                  Receive timely updates about your driver, including arrival
                  times, contact information, and vehicle details. This feature
                  ensures a smooth and stress-free transportation experience
                  during your travels.
                </p>
              </div>
            </div>
            <div className="flex gap-3 p-5 rounded-[35px] shadow-inner bg-white flex-col items-center text-center md:flex-row md:text-start md:items-start">
              <img src={Icon4} className="max-w-14" alt="journey-x" />
              <div className="flex flex-col flex-start justify-center">
                <p className="font-bold text-lg poppins font-newblack">
                  QR Ticket Notifications
                </p>
                <p className="text-slate-500 text-sm inter">
                  Simplify your travel with QR code ticket notifications. Easily
                  access and manage your tickets with a quick scan, allowing you
                  to focus on enjoying your journey without worrying about
                  keeping track of physical tickets.
                </p>
              </div>
            </div>
            <div className="flex gap-3 p-5 rounded-[35px] shadow-inner bg-white flex-col items-center text-center md:flex-row md:text-start md:items-start">
              <img src={Icon5} className="max-w-14" alt="journey-x" />
              <div className="flex flex-col flex-start justify-center">
                <p className="font-bold text-lg poppins">
                  24x7 Customer Support
                </p>
                <p className="text-slate-500 text-sm inter">
                  Our dedicated customer support team is available around the
                  clock to assist you with any questions or issues. Whether you
                  need help planning your trip or encounter an issue while
                  traveling, we are here to provide prompt and efficient
                  support.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-center items-baseline relative mt-10">
          <img src={Mobile} className=" mt-14 md:mt-10 " alt="journey-x" />
          <img
            src={Background}
            className="absolute z-[-1] left-[-30px] md:top-[-70px] md:left-[-20px] "
            alt="journey-x"
          />
        </div>
      </div>
    </div>
  );
}

export default section3;
