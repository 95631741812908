import React from "react";
import logo from "../../assets/logo.svg";
import playstore from "../../assets/googlepay.svg";
import appstore from "../../assets/appstore.svg";
import Download from "../../assets/home/downloadtheapp.svg";
import getiton from "../../assets/home/getiton.svg";
import appstorelogo from "../../assets/home/appstore.svg";
import googleplaystore from "../../assets/home/googleplay.svg";
function header() {
  return (
    <header className="container mx-auto p-5 md:px-8 lg:px-20">
      <div className="flex justify-between gap-4 items-center">
        <a href="/">
          <img src={logo} className="w-60" alt="logo" />
        </a>
        <div className="flex flex-col gap-2 md:flex-row md:gap-5">
          <div>
            <div className="flex gap-2 rounded-md max-w-48">
              <img src={appstore} alt="logo" />
            </div>
          </div>
          <div>
            <div className="flex gap-2 rounded-md max-w-[194px]">
              <img src={playstore} alt="journey-x" />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default header;
