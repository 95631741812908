import React from "react";
import Phone from "../../assets/phone.svg";
import Mail from "../../assets/mail.svg";

function termsofcondition() {
  return (
    <div className="container mx-auto p-5 md:px-8 lg:px-20">
      <div className=" flex justify-start items-center gap-10 my-5 hidden lg:flex">
        <a href="/privacypolicy" className="hover">
          {" "}
          <p className="font-newblack text-xl hover font-semibold inter">
            Privacy Policy
          </p>
        </a>
        <a href="/terms&condition" className="hover">
          <p className="font-newblack text-xl hover underline underline-offset-8 font-semibold inter">
            Terms & Condition
          </p>
        </a>
        <a href="/termsofuse" className="hover">
          <p className="font-newblack hover text-xl font-semibold inter">
            Terms of Use
          </p>
        </a>
      </div>
      <div className="my-5 md:my-20">
        <h1 className="font-semibold text-center font-newblack text-[40px] leading-[48px] md:text-[69px] md:leading-[82px] mb-3">
          Terms & Condition
        </h1>
        <p className="font-newblack text-xl text-center font-bold inter">
          Last Updated: 25 July 2024
        </p>
      </div>
      <div className="grid grid-cols-1 gap-5 my-10 lg:grid-cols-3 md:gap-10 md:my-24">
        <div className="col-span-2 order-last lg:order-none">
          <p className="text-2xl font-bold poppins font-newblack pt-2 md:pt-6 md:text-3xl">
            Terms & Condition
          </p>
          <p className="pt-2 inter text-slate-500 mb-5 font-medium text-md leading-[30px]">
            These Terms and Conditions ("Terms", "Terms and Conditions") govern
            your use of the JourneyX mobile application ("App") and website
            ("Site") operated by Advance Adventures Gear Technologies Pvt Ltd
            ("TechAAG", "we", "us", or "our"). By accessing or using the App or
            Site, you agree to be bound by these Terms. If you do not agree with
            these Terms, please do not use the App or Site.
          </p>
          <p className="text-2xl font-bold poppins font-newblack pt-2 md:pt-6 md:text-3xl">
            Terms & Condition Summary
          </p>
          <div id="acceptanceofterms">
            <p className="text-xl font-bold poppins font-newblack pt-2 md:pt-6 ">
              1. Acceptance of Terms
            </p>
            <p className="pt-2 inter text-slate-500 font-medium text-md leading-[30px]">
              By using the App or Site, you acknowledge that you have read,
              understood, and agree to be bound by these Terms, as well as our
              Privacy Policy. If you do not agree with any part of these Terms,
              you must immediately stop using the App and Site.
            </p>
          </div>
          <div id="appandsite">
            <p className="text-xl font-bold poppins font-newblack pt-2 md:pt-6 ">
              2. Use of the App and Site
            </p>
            <p className="pt-2 inter text-slate-500 font-medium text-md leading-[30px]">
              <span className="text-black underline">2.1 Eligibility</span> You must be at
              least 18 years old to use the App and Site. By using the App and
              Site, you represent and warrant that you are at least 18 years
              old.
              <br />{" "}
              <span className="text-black underline">2.2 Account Registration </span> To
              access certain features of the App or Site, you may be required to
              register for an account. You agree to provide accurate, current,
              and complete information during the registration process and to
              update such information to keep it accurate, current, and
              complete. You are responsible for safeguarding your account login
              information and for any activities or actions under your account.
              <br />
              <span className="text-black underline">2.3 Prohibited Activities</span> You
              agree not to:
              <br />
              <ul className="list-disc ml-5">
                <li>
                  Use the App or Site for any unlawful purpose or in violation
                  of any local, state, national, or international law.
                </li>
                <li>Harass, threaten, or defraud other users.</li>
                <li>
                  {" "}
                  Post, upload, or distribute any content that is illegal,
                  harmful, offensive, or otherwise objectionable.
                </li>
                <li>
                  Use any robot, spider, scraper, or other automated means to
                  access the App or Site.
                </li>
                <li>
                  {" "}
                  Interfere with or disrupt the App or Site, servers, or
                  networks.
                </li>
                <li>
                  {" "}
                  Impersonate any person or entity, or falsely state or
                  otherwise misrepresent your affiliation with a person or
                  entity.
                </li>
              </ul>
            </p>
          </div>
          <div id="intellectualproperty">
            <p className="text-xl font-bold poppins font-newblack pt-2 md:pt-6 ">
              3. Intellectual Property
            </p>
            <p className="pt-2 inter text-slate-500 font-medium text-md leading-[30px]">
              The App and Site, including all content, features, and
              functionality, are owned by TechAAG, its licensors, or other
              providers of such material and are protected by Indian and
              international copyright, trademark, patent, trade secret, and
              other intellectual property or proprietary rights laws.
            </p>
          </div>
          <div id="usercontent">
            <p className="text-xl font-bold poppins font-newblack pt-2 md:pt-6 ">
              4. User Content
            </p>
            <p className="pt-2 inter text-slate-500 font-medium text-md leading-[30px]">
              <span className="text-black underline">4.1 User Content</span> You may be
              able to create, post, or share content through the App or Site
              ("User Content"). You retain all rights in, and are solely
              responsible for, the User Content you post.
              <br />
              <span className="text-black underline">
                {" "}
                4.2 License to User Content
              </span>{" "}
              By posting User Content, you grant TechAAG a non-exclusive,
              transferable, sub-licensable, royalty-free, worldwide license to
              use, copy, modify, create derivative works based upon, distribute,
              publicly display, and publicly perform your User Content in
              connection with operating and providing the App and Site.
            </p>
          </div>
          <div id="bookingsandpayments">
            <p className="text-xl font-bold poppins font-newblack pt-2 md:pt-6 ">
              5. Bookings and Payments
            </p>
            <p className="pt-2 inter text-slate-500 font-medium text-md leading-[30px]">
              <span className="text-black underline">5.1 Travel Bookings</span> The App
              and Site may allow you to make travel bookings and reservations.
              All bookings are subject to availability and the terms and
              conditions of the relevant travel service provider. <br />{" "}
              <span className="text-black underline">5.2 Payments</span> You agree to pay
              all fees and charges associated with your bookings, including any
              applicable taxes and fees. Payments are processed through
              third-party payment processors. TechAAG is not responsible for any
              errors or issues arising from the payment process.
            </p>
          </div>
          <div id="disclaimers">
            <p className="text-xl font-bold poppins font-newblack pt-2 md:pt-6 ">
              6. Disclaimers
            </p>
            <p className="pt-2 inter text-slate-500 font-medium text-md leading-[30px]">
              The App and Site are provided on an "as is" and "as available"
              basis. TechAAG makes no representations or warranties of any kind,
              express or implied, as to the operation of the App or Site, or the
              information, content, or materials included therein. To the
              fullest extent permitted by applicable law, TechAAG disclaims all
              warranties, express or implied, including but not limited to
              implied warranties of merchantability and fitness for a particular
              purpose.
            </p>
          </div>
          <div id="limitationofliability">
            <p className="text-xl font-bold poppins font-newblack pt-2 md:pt-6 ">
              7. Limitation of Liability
            </p>
            <p className="pt-2 inter text-slate-500 font-medium text-md leading-[30px]">
              To the fullest extent permitted by applicable law, in no event
              shall TechAAG, its affiliates, directors, employees, or agents be
              liable for any indirect, incidental, special, consequential, or
              punitive damages, including but not limited to loss of profits,
              data, use, goodwill, or other intangible losses, resulting from:
              <ul className="list-disc text-black ml-5">
                <li>
                  Your access to or use of, or inability to access or use, the
                  App or Site.
                </li>
                <li>
                  Any conduct or content of any third party on the App or Site.
                </li>
                <li>Any content obtained from the App or Site.</li>
                <li>
                  {" "}
                  Unauthorized access, use, or alteration of your transmissions
                  or content.
                </li>
              </ul>
            </p>
          </div>
          <div id="indemnification">
            <p className="text-xl font-bold poppins font-newblack pt-2 md:pt-6 ">
              8. Indemnification
            </p>
            <p className="pt-2 inter text-slate-500 font-medium text-md leading-[30px]">
              You agree to defend, indemnify, and hold harmless TechAAG, its
              affiliates, and their respective officers, directors, employees,
              and agents from and against any claims, liabilities, damages,
              losses, and expenses, including without limitation reasonable
              legal and accounting fees, arising out of or in any way connected
              with your access to or use of the App or Site, or your violation
              of these Terms.
            </p>
          </div>
          <div id="termination">
            <p className="text-xl font-bold poppins font-newblack pt-2 md:pt-6 ">
              9. Termination
            </p>
            <p className="pt-2 inter text-slate-500 font-medium text-md leading-[30px]">
              We may terminate or suspend your access to the App or Site
              immediately, without prior notice or liability, for any reason
              whatsoever, including without limitation if you breach these
              Terms. Upon termination, your right to use the App or Site will
              immediately cease.
            </p>
          </div>
          <div id="governinglaw">
            <p className="text-xl font-bold poppins font-newblack pt-2 md:pt-6 ">
              10. Governing Law
            </p>
            <p className="pt-2 inter text-slate-500 font-medium text-md leading-[30px]">
              These Terms shall be governed and construed in accordance with the
              laws of India, without regard to its conflict of law provisions.
              You agree to submit to the personal jurisdiction of the courts
              located in Mumbai, India, for the purpose of litigating all such
              claims or disputes.
            </p>
          </div>
          <div id="changestoterms">
            <p className="text-xl font-bold poppins font-newblack pt-2 md:pt-6 ">
              11. Changes to Terms
            </p>
            <p className="pt-2 inter text-slate-500 font-medium text-md leading-[30px]">
              We reserve the right, at our sole discretion, to modify or replace
              these Terms at any time. If a revision is material, we will
              provide at least 30 days' notice prior to any new terms taking
              effect. What constitutes a material change will be determined at
              our sole discretion. Your continued use of the App or Site
              following the posting of revised Terms means that you accept and
              agree to the changes.
            </p>
          </div>
          <div id="contactus">
            <p className="text-xl font-bold poppins font-newblack pt-2 md:pt-6 ">
              12. Contact us
            </p>
            <p className="pt-2 inter text-slate-500 font-medium text-md leading-[30px]">
              If you have any questions or concerns about this Privacy Policy or
              our privacy practices, please contact us:
              <br />
              <span className="text-black">
                Advance Adventures Gear Technologies Pvt Ltd
              </span>
              <span className="flex gap-2 my-2 text-black">
                <img src={Mail} alt="journey-x" />
                <a href="mailto:dev@techaag.com" className="hover">
                  dev@techaag.com
                </a>
              </span>
              <span className="flex gap-2 mb-2 text-black">
                <img src={Phone} alt="journey-x" />
                <a href="tel:+91-2265168147" className="hover">
                  +91-2265168147
                </a>
              </span>
            </p>
          </div>
        </div>
        <div>
          <p className="text-2xl font-bold poppins font-newblack py-2 md:pt-6 ">
            Table of Content:
          </p>
          <ul className="list-disc text-black ml-5">
            <a href="#acceptanceofterms">
              <li className="underline mb-2 hover">1. Acceptance of Terms</li>
            </a>
            <a href="#appandsite">
              <li className="underline mb-2 hover">
                {" "}
                2. Use of the App and Site
              </li>
            </a>
            <a href="#intellectualproperty">
              <li className="underline mb-2 hover">3. Intellectual Property</li>
            </a>
            <a href="#usercontent">
              <li className="underline mb-2 hover">4. User Content</li>
            </a>
            <a href="#bookingsandpayments">
              <li className="underline mb-2 hover">5. Bookings and Payments</li>
            </a>
            <a href="#disclaimers">
              <li className="underline mb-2 hover">6. Disclaimers</li>
            </a>
            <a href="#limitationofliability">
              <li className="underline mb-2 hover">
                7. Limitation of Liability
              </li>
            </a>
            <a href="#indemnification">
              <li className="underline mb-2 hover">8. Indemnification</li>
            </a>
            <a href="#termination">
              <li className="underline mb-2 hover">9. Termination</li>
            </a>

            <a href="#governinglaw">
              <li className="underline mb-2 hover">10. Governing Law</li>
            </a>
            <a href="#changestoterms">
              <li className="underline mb-2 hover">11. Changes to Terms</li>
            </a>
            <a href="#contactus">
              <li className="underline mb-2 hover">12. Contact Us</li>
            </a>
          </ul>
          <hr className="mt-10 h-0.5" />
        </div>
      </div>
    </div>
  );
}

export default termsofcondition;
