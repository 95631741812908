import React from "react";
import Mobile from "../../assets/home/footer/Mobile.png";
import IndiaFlag from "../../assets/home/footer/India flag.svg";
import Heart from "../../assets/home/footer/heart.svg";
import Arrow from "../../assets/home/footer/arrow.svg";
import Plane from "../../assets/home/footer/plane.svg";
// import Maps from "../../assets/home/section1/Maps.svg";
import playstore from "../../assets/googlepay.svg";
import appstore from "../../assets/appstore.svg";
function index() {
  return (
    <div>
      <footer
        style={{
          background:
            "linear-gradient(rgba(24, 119, 242, 0.2), rgba(229, 30, 37, 0.2))",
        }}
      >
        <div className="container mx-auto pt-10 pb-4 px-5 md:px-8 md:pt-24 lg:px-32">
          <div className="grid grid-cols-1 pb-10 md:grid-cols-2">
            <div className="relative">
              <img src={Mobile} alt="journey-x" />
              <img
                src={Arrow}
                alt="journey-x"
                className="absolute z-[-1] top-[60px] left-[-120px] hidden md:block"
              />
              <img
                src={Plane}
                alt="journey-x"
                className="absolute top-[30px] left-[-30px] hidden md:block"
              />
              {/* <img src={Maps} alt="journey-x" className="absolute top-0 left-[30%]" /> */}
            </div>
            <div className="flex flex-col justify-center gap-7 items-center">
              <p className="font-semibold text-lg font-newblack poppins text-center">
                Download The <span className="font-blue">Journey</span>
                <span className="font-red">X</span> App Now!
              </p>
              <div className="flex flex-col gap-4">
                <div>
                  <div className="flex gap-2 rounded-m max-w-48">
                    <img src={appstore} alt="logo" />
                  </div>
                </div>
                <div>
                  <div className="flex gap-2 rounded-md max-w-[194px]">
                    <img src={playstore} alt="journey-x" />
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-5">
                <p className="font-semibold font-newblack flex gap-2 items-center justify-center poppins">
                  Product by India <img src={IndiaFlag} alt="journey-x" />
                  <img src={Heart} alt="journey-x" />
                </p>
                <p className="font-normal text-base gap-2 itens-center poppins hidden md:flex">
                  <a href="/aboutus">About us</a> |{" "}
                  <a href="/privacypolicy">Privacy Policy</a> |{" "}
                  <a href="/terms&condition">Terms & Condition</a> |{" "}
                  <a href="/termsofuse">Terms of use</a>
                </p>
                <div className="font-normal text-base flex flex-col gap-2 items-center poppins md:hidden">
                  <a href="/aboutus">About us</a>
                  <a href="/privacypolicy">Privacy Policy</a>
                  <a href="/terms&condition">Terms & Condition</a>
                  <a href="/termsofuse">Terms of use</a>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col text-center justify-between md:flex-row">
            <a href="/" className="inter text-base">
              Copyright @ 2024 Advanced Adventures Gear Technologies Pvt. Ltd.
            </a>
            {/* <hr className="md:hidden"></hr> */}
            <a href="http://techaag.com/" target="_blank"><p className="inter text-base">Designed & Developed by TechAAG </p></a>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default index;
